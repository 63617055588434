<template>
	<div>
		<hero title="How Can We Help You?" class="mb-6">
			<h4 class="text-grey-2">Below you'll find some <span class="moving-underline" v-in-viewport>frequently asked questions</span> about WiseTech Academy and our online courses.</h4>
		</hero>
		<b-container id="FAQs">
			<b-row>
				<b-col>
					<heading>FAQs</heading>
				</b-col>
			</b-row>
			<b-row @click="onClick">
				<b-col md="6" class="mb-3 mb-md-0">
					<h5 class="text-grey">Enrolment</h5>
					<accordian id="faq1" :items="faqs[0]" class="mb-4 mb-md-3" group="support"></accordian>
					<h5 class="text-grey">Logging In</h5>
					<accordian id="faq2" :items="faqs[1]" class="mb-4 mb-md-3" group="support"></accordian>
					<h5 class="text-grey">Online Training</h5>
					<accordian id="faq3" :items="faqs[2]" group="support"></accordian>
				</b-col>
				<b-col md="6">
					<h5 class="text-grey">Approved Arrangements Accreditation Training for Accredited Persons (Classes 1-8)</h5>
					<accordian id="faq4" :items="faqs[3]" group="support"></accordian>
				</b-col>
			</b-row>
		</b-container>
		<contact-form title="Need More Information?" full support class="mt-6" ref="enquiry-form" id="contact"/>
	</div>
</template>

<script>
export default {
	name: 'Support',
	computed: {
		faqs: () => [[
			{title: "I'm having trouble enrolling in a course. Who can help me?", content: `<p>Please contact us via our <a href="#enquiry-form">online enquiry form</a>.</p>`},
			{title: "I've used an incorrect email address for registration. What do I do?", content: `<p>Please contact us via our <a href="#enquiry-form">online enquiry form</a>.</p>`},
			{
title: "Why haven't I received my enrolment confirmation and login details?", content: `<p>A spam filter may be blocking email messages sent to you by WiseTech Academy.</p>
<p>You may need to add the WiseTech Academy system's email domain (https://wisetech.academy/) to your email client or your company's mail server whitelist in order to receive our emails.</p>`
},
			{
title: "Can I share my username and password?", content: `<p>No, we require each person to be individually registered.</p>
<p>To successfully complete your WiseTech Academy course, you're required to pass a final assessment.</p>
<p>The training cannot be completed successfully using a shared user registration.</p>`
},
			{title: "How long do you keep my training records?", content: `<p>All training records are stored securely for a minimum of five years. You may return to the system at any time to reprint your certificates using your username and password.</p>`}
		], [
			{
title: "How do I login to WiseTech Academy training?", content: `<p>Go to the top right of your browser window and click on the <a href="#login">Log In</a> option.</p>
<p>Enter your username and password on the form and click 'submit'.</p>`
},
			{
title: "I can't remember my username or password. What do I do?", content: `<p>Access our <a href="#forgot-password">Forgotten My Password</a> function. You'll need to enter your username or email address.</p>
<p>An email containing your username and password will be sent to your registered email address.</p>`
}
		], [
			{
title: "Do I need to complete the whole course in one sitting?", content: `<p>No, you can enter and leave the course as often as you like, and you will always be returned to the same point in the course where you left it. However, you will need to sit your assessments in one attempt for each subject.</p>`
},
			{
title: "What happens if I don't pass the assessment?", content: `<p>To minimize the risk of not passing your final assessment, our courses include a practice test that you can take prior to attempting the final assessment. 
    You can attempt the final assessment up to five times.</p> `
},
			{
title: "Do I receive a certificate on completion of my training program?", content: `<p>Yes, after successfully completing your WiseTech Academy training course, you'll be able to download and print a Certificate of Completion. Simply log on to  
    WiseTech Academy training and select the 'Certificate' icon for the completed course.</p>`
},
		], [
{
title: "NOTE: we can only provide advice on training matters.",
content: "<p>The WiseTech Academy can only provide advice on matters relating to the training provided on this website. If you require information of a specific nature about the Approved Arrangements (AA) requirements, you will need to contact the Department of Agriculture, Water and the Environment (DAWE) at  or call them on 1800 900 090</p>"
}, {
title: "What is the fee for the Accreditation course?",
content: "<p>As a start-up bonus, WiseTech Academy is offering the Approved Arrangements accreditation course free of charge to industry until mid-November 2020. We welcome your involvement in our course and would appreciate your feedback.</p>"
}, {
title: "How is the training conducted?",
content: "<p>The AA accreditation training is an engaging self-paced, online study program, written by industry experts and approved by DAWE to meet the required accreditation standard. </p>"
}, {
title: "How long will it take me to complete the AA accreditation course?",
content: "<p>The course is self-paced so you can take as long as you like to complete it. As a guide however, we would suggest you allow three hours to complete the full program. You can, of course, pause at any point and come back to your study later where you will be returned to the same point in the course where you left.</p>"
}, {
title: "What are the subjects in the AA accreditation course?",
content: "<p>The AA course comprises two subjects: Biosecurity Awareness and the AA Accreditation. You are required to take these subjects in this order to meet your accreditation requirements and to ensure you get the most out of the course. </p>"
}, {
title: "Do I have to pass an exam?",
content: "<p>Yes, there is an assessment to take at the completion of each subject within the AA course. The pass mark for each assessment is 75% and you will be able to have more than one attempt if you are unsuccessful at your first attempt. The course also contains a practice test and we highly recommend you undertake this test as many times as you like until you are comfortable you can pass the final assessment.    </p>"
}, {
title: "I am already an Accredited Person, so do I need to undertake this training?",
content: "<p>If you undertook your AA Accreditation training (Classes 1-8) and have a certificate dated February 2015 or later, then your accreditation is still valid, and you do not need to undertake this training. Your current accreditation also provides you with accreditation for Class 10-12. You will be advised by DAWE when it is time to renew your accreditation. </p>"
}, {
title: "Do I get a certificate once I have completed the course?",
content: "<p>Yes, upon successful completion of the assessment, you will be given access to print out your own certificate. Just go to My Courses and navigate to the AA Accreditation course. Look for the certificate icon and click this to have your certificate PDF open up in a new browser tab or window, ready for you to print. </p>"
}, {
title: "Who do I contact for assistance with registration, including usernames and passwords?",
content: "<p>Please complete the contact form below if you require any assistance.</p>"
}]]
	},
	methods: {
		onClick(e) {
			const hash = e.target.hash
			if (hash) e.preventDefault() 
			else return
			if (hash == '#enquiry-form') {
				document.getElementById('name').focus()
				this.$refs['enquiry-form'].$el.scrollIntoView({behavior: "smooth", block: "nearest"})
			} else if (hash == "#login") {
				this.$store.commit('login/login')
			} else if (hash == "#forgot-password") {
				this.$store.commit('login/forgotPassword')
			}
		}
	}
}
</script>